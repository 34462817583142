import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { LandingPage } from 'modules/LandingPage';
import { AboutView } from 'modules/About';
import { FallbackView } from 'modules/FallbackView';
import { DisclamerView } from 'modules/Disclamer';
import { ParpView } from 'modules/Parp';
import { SubscribedView } from 'modules/SubscribedView';
import { UnsubscribedView } from 'modules/UnsubscribedView';
import { TermsOfServiceView } from 'modules/TermsOfService';
import { PricingView } from 'modules/Pricing';

export const Navigation = () => (
  <BrowserRouter>
    <Routes>
      <Route path="" element={<LandingPage />} />
      <Route path="/about" element={<AboutView />} />
      <Route path="/pricing" element={<PricingView />} />
      <Route path="/disclamer" element={<DisclamerView />} />
      <Route path="/parp" element={<ParpView />} />
      <Route path="/terms" element={<TermsOfServiceView />} />
      <Route path="/newsletter/confirm" element={<SubscribedView origin="newsletter" />} />
      <Route path="/waitlist/confirm" element={<SubscribedView origin="waitlist" />} />
      <Route path="/newsletter/unsubscribe" element={<UnsubscribedView />} />
      <Route path="/*" element={<FallbackView />} />
    </Routes>
  </BrowserRouter>
);
