import styled from 'styled-components/macro';

import { Flex, Gradient, Heading, Layout, Paragraph } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';
import { theme } from 'theme';
import { SkytradingLogo, TickIcon } from 'assets/SVGs';

const pricings = [
  {
    title: 'Free',
    subtitle: 'Microtransactions',
    elements: [
      'No code strategy builder',
      'Backtester',
      'Adaptive Trading Strategy Optimizer',
      'AI Trading Assistant',
      'Paper Trading',
    ],
  },
  {
    title: 'Subscription',
    subtitle: '99$/m',
    elements: [
      'No code strategy builder',
      'Backtester',
      'Adaptive Trading Strategy Optimizer',
      'AI Trading Assistant',
      'Paper Trading',
      'Live Trading',
      'Marketplace',
    ],
  },
  {
    title: 'Enterprise',
    subtitle: 'Contact sales team for individual offer',
    elements: [],
  },
];

export const PricingView = () => {
  const { isMobile, isSmallTablet } = useResponsiveVariant();

  return (
    <Layout>
      <Styled.Container isMobile={isMobile}>
        {pricings.map(({ title, subtitle, elements }) => (
          <Styled.Card key={title} isSmallTablet={isSmallTablet} isMobile={isMobile}>
            <Flex gap={20} direction="column">
              <Gradient>
                <Heading level={isSmallTablet && !isMobile ? 'h5' : 'h4'} style={{ textTransform: 'uppercase' }}>
                  {title}
                </Heading>
              </Gradient>
              <Paragraph size="l" color={theme.colors.white[2]} align="center">
                {subtitle}
              </Paragraph>
              <Styled.List>
                {elements.length === 0 ? (
                  <Paragraph>hello@skytrading.io</Paragraph>
                ) : (
                  elements.map((element) => (
                    <Styled.ListElement key={element}>
                      <TickIcon />
                      {element}
                    </Styled.ListElement>
                  ))
                )}
              </Styled.List>
            </Flex>
            <SkytradingLogo />
          </Styled.Card>
        ))}
      </Styled.Container>
    </Layout>
  );
};

const Styled = {
  Container: styled.div<{ isMobile: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 180px auto;
  `,

  Card: styled.section<{ isSmallTablet: boolean; isMobile: boolean }>`
    background: linear-gradient(306.76deg, rgba(42, 42, 42, 0.32) 49.14%, rgba(75, 75, 75, 0.32) 113.65%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${({ isSmallTablet, isMobile }) =>
      isSmallTablet && !isMobile ? '36px 12px 12px 12px' : '50px 20px 20px 20px'};
    border-radius: 20px;
    width: ${({ isSmallTablet, isMobile }) => (isMobile ? '70vw' : isSmallTablet ? '300px' : '400px')};
    height: 600px;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;

    &:nth-child(2) {
      transform: scale(1.08);

      &:hover {
        transform: scale(1.13);
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  `,

  List: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    margin: 0;
    padding: 12px;
  `,

  ListElement: styled.li`
    display: flex;
    gap: 12px;
  `,
};
